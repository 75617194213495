/* eslint-disable no-unused-vars */
import React, { Component , useRef}  from "react";
import  { Redirect } from 'react-router-dom'

// import  { useRef   }  from "react";
// import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Moment from 'moment';
import validator from 'validator';
import MainLayout from "../layout/main-layout";
import Header from '../layout/Header';
import Left from '../layout/Left';
import DatePicker from "react-datepicker";
import valid from "card-validator";
import "react-datepicker/dist/react-datepicker.css";
import { getClient, getPaymentInfo, setCellRequest,GetClientVialsService,verifyClientData,getShippingAmount  } from '../../actions/payActions'
// import { set } from "core-js/core/dict";
import NumberFormat from 'react-number-format';



class StemCellRequest extends Component {

    constructor(props){
        super(props);
        this.state={
            error:'',
            errors:{},
            formData:{delivery_speed:"Immediate",EstimatedVialFrequency:"Monthly",shipping_method:"FedEx",CPP:"Yes",ShippingFeeCharged:45,Source:'ACT Client Portal',CreditCardProcessingFee:0},
            shippindetails:{},
            login:true,                       
            client_test:"",
            client_info:"",
            Dob:'',
            ShipmentDate:"",
            card_last_gid:"",
            card_info:{},
            update_card:false,
            loading:true,
            base_price:500,
            shipping:45, 
            ShippingFeeCharged:45,           
            amount:0,
            shipping_info:"false",
            cpp_price:0,
            NumberOfVials:0,
            cpp:true,
            save_card:false,
            save_ach:false,
            verify_client:false,
            Client_id:"",
            Client_lastname:"",
            Infectious_Disease:"",
            shipping_value:"",
            longevityVialsAvailable: "",
            myFutureDate:"",
            shipping_amount:"",
            Payment_Type:"",
            clientConsentFormCompleted:false,
            ShippingIntegration_req :{
                "weightUnit": "LB",
                "totalWeight": null,
                "totalSurcharge": null,
                "totalCharge": null,
                "stockType": "PAPER_4X6",
                "specialService": {
                  "type": null,
                  "requestTime": null,
                  "requestDate": null,
                  "phoneNumber": null,
                  "lithiumBatteries": [
                    {
                      "quantity": 1,
                      "batteryDetail": {
                        "regulatorySubType": null,
                        "packing": null,
                        "material": null
                      }
                    }
                  ],
                  "endDate": null,
                  "customDeliveryWindowType": null,
                  "currencyUnit": "USD",
                  "codCollectionType": null,
                  "codAmount": null,
                  "beginsDate": null,
                  "addressInfo": {
                    "urbanizationCode": null,
                    "streetLines": null,
                    "stateOrProvinceCode": null,
                    "residential": null,
                    "postalCode": null,
                    "countryCode": null,
                    "city": null
                  }
                },
                "signatureOption": "SERVICE_DEFAULT",
                "shippingCarrier": "FedEx",
                "shipDate": "2022-11-23",
                "serviceType": "PRIORITY_OVERNIGHT",
                "senderInfomration": {
                  "streetLine": "13794 NW 4th Street",
                  "state": "FL",
                  "postalCode": "33325",
                  "phoneNumber": "844-443-2355",
                  "isItResidentialAddress": false,
                  "email": "info@americancelltechnology.com",
                  "country": "US",
                  "contactPersonName": "American Cell Technology",
                  "companyName": "American Cell Technology",
                  "city": "Florida",
                  "buildingType": null
                },
                "selectedSpecialServices": [],
                "referenceNumbers": [
                  {
                    "value": null,
                    "code": null,
                    "barCodeIndicator": null
                  },
                  {
                    "value": null,
                    "code": null,
                    "barCodeIndicator": null
                  }
                ],
                "recipientInfomration": {
                  "streetLine": "",
                  "state": "",
                  "postalCode": "",
                  "phoneNumber": "",
                  "isItResidentialAddress": false,
                  "email": "",
                  "country": "",
                  "contactPersonName": "",
                  "companyName": "",
                  "city": "",
                  "buildingType": null
                },
                "printing_Orientation": "TOP_EDGE_OF_TEXT_FIRST",
                "packagingType": "YOUR_PACKAGING",
                "packageList": [
                  {
                    "weight": 1.06,
                    "unitPrice": null,
                    "unitOfmeasurement": "PCS",
                    "signatureOption": null,
                    "referenceNumbers": null,
                    "quantity": 1,
                    "itemDescription": null,
                    "insuredValue": null,
                    "harmonizedCode": null,
                    "dimensionsWidth": null,
                    "dimensionsLength": null,
                    "dimensionsHeight": null,
                    "countryOfManufacturing": "US"
                  }
                ],
                "packageBillType": "Not Applicable",
                "machinable": null,
                "linearUnit": "IN",
                "labelType": "PDF",
                "isItReturnShipment": false,
                "goodsDescription": null,
                "formatType": "COMMON2D",
                "expectedDeliveryDate": null,
                "dropoffType": "REGULAR_PICKUP",
                "currencyUnit": "USD"
              }
              


        }
    }

    componentDidMount() {            
        var client_id = localStorage.getItem('client_id'); 

        console.log('clinet id ---------------------- ', client_id);
        if(client_id){
            this.props.getPaymentInfo(client_id, this.props.history);
                   
            
            
            this.state.formData['Client_id'] = client_id
            this.setState({Client_id:client_id}) 

            this.props.getClient(client_id, this.props.history);  
           
        }    
        var myCurrentDate=new Date();
        var myFutureDate=new Date(myCurrentDate);
        myFutureDate.setDate(myFutureDate.getDate()+ 21);
        this.setState({myFutureDate:myFutureDate})
         
       
        
        // console.log("param => ",this.props.match.params.id)

        
                
    }

    numberFormat = (value) =>
    new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'USD'
    }).format(value);

   
    checkShippingAmount = (e) => {

        console.log('click on shipping button');
        let errors = {};
        let shipping_chk = true;
        if(this.state.shipping_value != ""){
            console.log(this.state.shipping_value);
            errors["shipping_amount"] ="";
            console.log('shipping_info ---------- ',this.state.shipping_info);

            if(this.state.shipping_value=="yes"){
                this.setState({shipping_amount:"yes"});
            this.state.ShippingIntegration_req.recipientInfomration['contactPersonName'] = this.state.shippindetails['firstName'] 
            this.state.ShippingIntegration_req.recipientInfomration['companyName'] = this.state.shippindetails['firstName'] 
            this.state.ShippingIntegration_req.recipientInfomration['phoneNumber'] = this.state.shippindetails['phone'] 
            this.state.ShippingIntegration_req.recipientInfomration['streetLine'] = this.state.shippindetails['street']
            this.state.ShippingIntegration_req.recipientInfomration['city'] = this.state.shippindetails['city']
            this.state.ShippingIntegration_req.recipientInfomration['state'] = this.state.shippindetails['state']
            this.state.ShippingIntegration_req.recipientInfomration['country'] = this.state.shippindetails['country']
            this.state.ShippingIntegration_req.recipientInfomration['postalCode'] = this.state.shippindetails['ZipCode']


            }else{
                if(this.state.formData['street']!="" && this.state.formData['city']!="" && this.state.formData['state']!="" && this.state.formData['country']!="" && this.state.formData['ZipCode']!="" ) {

                this.setState({shipping_amount:"yes"});

                this.state.ShippingIntegration_req.recipientInfomration['contactPersonName'] = this.state.formData['firstName'] 
                this.state.ShippingIntegration_req.recipientInfomration['companyName'] = this.state.formData['firstName'] 
                this.state.ShippingIntegration_req.recipientInfomration['phoneNumber'] = this.state.formData['phone'] 
                this.state.ShippingIntegration_req.recipientInfomration['streetLine'] = this.state.formData['street']
                this.state.ShippingIntegration_req.recipientInfomration['city'] = this.state.formData['city']
                this.state.ShippingIntegration_req.recipientInfomration['state'] = this.state.formData['state']
                this.state.ShippingIntegration_req.recipientInfomration['country'] = this.state.formData['country']
                this.state.ShippingIntegration_req.recipientInfomration['postalCode'] = this.state.formData['ZipCode']
                }else{
                   errors["shipping_amount"] = "Please enter shipping details";
                   shipping_chk = false;
                   this.setState({shipping_amount:""});
                }
            }


            if(shipping_chk == true){ 
                let para = this.state.ShippingIntegration_req;
                this.props.getShippingAmount(para, this.props.history);
                this.setState({loading:true})
            }
            

        }else{
            if(this.state.formData['shipping_method'] == 'FedEx') {
                errors["shipping_amount"] = "Please select valid shipping info";
            }
           
            
        }
        this.setState({errors: errors});
    }
   



    checkVial = (e) =>{
        this.state.formData[e.target.id] = e.target.value;       
        // const newList = this.state.formData;
        const para = {'NumberOfVials':this.state.formData['NumberOfVials'], Client_id:this.state.Client_id};
        console.log("newlist=>", para);
        this.props.GetClientVialsService(para, this.props.history);
        this.setState({loading:true})

        if(this.state.formData['Free_Bio_Insurance_Cells_Available']){
            if(this.state.formData['Free_Bio_Insurance_Cells_Available'] >= this.state.formData[e.target.id]){
                var myCurrentDate=new Date();
                var myFutureDate=new Date(myCurrentDate);
                myFutureDate.setDate(myFutureDate.getDate()+ 2);
                this.setState({myFutureDate:myFutureDate})
            }else{
                var myCurrentDate=new Date();
                var myFutureDate=new Date(myCurrentDate);
                myFutureDate.setDate(myFutureDate.getDate()+ 21);
                this.setState({myFutureDate:myFutureDate})
            }
        }

    }

    savecard = (e) => {
        if(this.state.save_card){
            this.setState({save_card:!this.state.save_card})
            this.state.formData['save_card'] = !this.state.save_card
        }
        else{
            this.setState({save_card:true})

            this.state.formData['save_card'] = true
        }

    }

    saveAch = (e) => {
        if(this.state.save_ach){
            this.setState({save_ach:!this.state.save_ach})
            this.state.formData['save_ach'] = !this.state.save_ach
        }
        else{
            this.setState({save_ach:true})

            this.state.formData['save_ach'] = true
        }
    }

    checkAch = (e) => {
        this.setState({update_ach:!this.state.update_ach})
        

        if(this.state.update_ach){
            this.state.formData["Account_Number"] = this.state.card_info['Account_Number']
            this.state.formData["Name_On_Account"] = this.state.card_info['Name_On_Account']
            this.state.formData["ABA_Routing_Number"] = this.state.card_info['ABA_Routing_Number']
        }else{
            this.state.formData["Account_Number"] = ""
            this.state.formData["Name_On_Account"] = ""
            this.state.formData["ABA_Routing_Number"] = ""
            // this.setState({update_ach:true})

            // this.setState({update_ach:true})

        }
        console.log('update_card =>', this.state.update_ach)


    }

    onChange = (e) =>{     
        
         if(e.target.id == "shipping_method"){
            if(e.target.value=="ACT VIP"){
                this.setState({shipping:0})
                this.state.formData['shipping_charge']=0;
                this.state.formData['ShippingFeeCharged'] = 0;

                this.state.formData['amount'] = this.state.amount+ 0 + this.state.cpp_price;
                
            }
            if(e.target.value=="FedEx"){
              this.setState({shipping:45});
              this.state.shipping = 45;
              this.state.formData['ShippingFeeCharged'] = 45;

              console.log('shipping value fedex -------',this.state.shipping);
               this.state.formData['amount'] = this.state.amount + this.state.shipping + this.state.cpp_price;
            }
           console.log('shipping select ')
            this.state.formData[e.target.id] = e.target.value;
            this.checkProcessingfee();

        }
        else if (e.target.id == "CPP"){
            if(e.target.value=="Yes"){
                this.setState({cpp:true})
                console.log('shipping value cpp yes -------',this.state.shipping)
                let am = this.state.formData['NumberOfVials'] * 25               
                this.state.formData['amount'] = this.state.amount+ this.state.shipping + am ;
                this.setState({cpp_price:am})
            // this.state.formData['CPPAmountCharged'] = am;
            this.state.formData['CPPAmountCharged'] = am;
            this.state.formData['ShippingFeeCharged'] = this.state.shipping;

                
            }else{
                this.setState({cpp:false})
                console.log('shipping value cpp no -------',this.state.shipping)

                this.state.formData['amount'] = this.state.amount+ this.state.shipping 
                this.setState({cpp_price:0})
            this.state.formData['CPPAmountCharged'] = 0;

            }
            this.state.formData[e.target.id] = e.target.value;
            this.checkProcessingfee();
            
            // console.log('price ', this.state.formData['amount'] )
        }
        else if (e.target.name == "Tissue"){
            this.state.formData['Tissue_Info'] = e.target.value;
        }
        else if (e.target.name == "Infectious_Disease"){
            this.state.formData['Infectious_Disease'] = e.target.value;
            if(e.target.value=="treated"){
                this.setState({Infectious_Disease:"yes"})
            }else{
                this.setState({Infectious_Disease:"no"})
            }
        }        
        else if(e.target.id == "Client_id"){
            this.state.formData[e.target.id] = e.target.value;
            // this.setState({Client_id:e.target.value})
        }
        else if(e.target.id == "Client_lastname"){
            this.state.formData[e.target.id] = e.target.value;
            this.setState({Client_lastname:e.target.value})
        }
        else if(e.target.id == "firstName" || e.target.id == "lastName"){
           this.state.formData[e.target.id] = e.target.value;
            this.setState({Client_lastname:e.target.value})
            this.state.formData['ShippingContactName'] = this.state.formData['firstName']+ ' ' +this.state.formData['lastName']
            
        }
        else{
            this.state.formData[e.target.id] = e.target.value; 
        }      
        console.log('form data => ',this.state.formData)
    }

    checkRadio = (e) => {
        if(e.target.id == "credit_card"){
            this.state.formData['CreditCard'] = true; 
            this.setState({CreditCard:e.target.value})
            
            this.state.formData['PaymentMethod'] = 'Credit Card';
            this.state.formData['PaymentMethod'] = 'Credit Card';
            this.state.formData["Credit_Card_Number"] = this.state.card_info['Credit_Card_Number']
            this.state.formData["CVV"] = this.state.card_info['CVV']
            this.state.formData["Credit_Card_Holder_First_Name"] = this.state.card_info['Credit_Card_Holder_First_Name']
            this.state.formData["Credit_Card_Holder_Last_Name"] = this.state.card_info['Credit_Card_Holder_Last_Name']
            this.state.formData["Month_of_Expiry"] = this.state.card_info['Month_of_Expiry']
            this.state.formData["Year_of_Expiry"] = this.state.card_info['Year_of_Expiry']

            this.state.formData['CreditCardProcessingFee'] = 0.03 * this.state.formData['amount'];

        }

        if(e.target.id == "shipment"){
            this.state.formData['CreditCard'] = false; 
            this.setState({CreditCard:e.target.value})
            this.state.formData['PaymentMethod'] = 'Check';

            // console.log('CreditCard =>', this.state.CreditCard)
        }

        if(e.target.id == "ACH_payment"){
            this.state.formData['CreditCard'] = false; 
            this.setState({CreditCard:e.target.value})
            this.state.formData['PaymentMethod'] = 'ACH Payment';
            this.state.formData['PaymentMethod'] = 'ACH Payment';
            this.state.formData["Credit_Card_Number"] = ""
            this.state.formData["CVV"] = ""
            this.state.formData['Credit_Card_Holder_First_Name'] = ""
            this.state.formData['Credit_Card_Holder_Last_Name'] = ""
            this.state.formData['Month_of_Expiry'] = ""
            this.state.formData['Year_of_Expiry'] = ""
            this.state.formData['CreditCardProcessingFee'] = 0 * this.state.formData['amount'];
      


            // console.log('CreditCard =>', this.state.CreditCard)
        }

        this.setState({Payment_Type:e.target.value})
        this.state.formData['Payment_Type'] = e.target.value; 

        
        // console.log('CreditCard =>', this.state.CreditCard)
        // console.log('target id  =>', e.target.id)
    }

    checkRadio3 = (e) => {     
        if(this.state.update_card){
            this.setState({update_card:!this.state.update_card})
            this.state.formData["Credit_Card_Number"] = this.state.card_info['Credit_Card_Number']
            this.state.formData["CVV"] = this.state.card_info['CVV']
            this.state.formData["Credit_Card_Holder_First_Name"] = this.state.card_info['Credit_Card_Holder_First_Name']
            this.state.formData["Credit_Card_Holder_Last_Name"] = this.state.card_info['Credit_Card_Holder_Last_Name']
            this.state.formData["Month_of_Expiry"] = this.state.card_info['Month_of_Expiry']
            this.state.formData["Year_of_Expiry"] = this.state.card_info['Year_of_Expiry']

        }
        else{
            this.state.formData["Credit_Card_Number"] = ""
            this.state.formData["CVV"] = ""
            this.state.formData['Credit_Card_Holder_First_Name'] = ""
            this.state.formData['Credit_Card_Holder_Last_Name'] = ""
            this.state.formData['Month_of_Expiry'] = ""
            this.state.formData['Year_of_Expiry'] = ""

            this.setState({update_card:true})
        }
        
        // console.log('update_card =>', this.state.update_card)
    }

    componentWillReceiveProps(nextProps) {
        console.log('nextProps ----------',nextProps); 
         
        // nextProps.page_name.page_name.listResponse = [];
        if(nextProps.page_name.pay_info && this.state.update_card==false){
            this.state.formData['Credit_Card_Holder_First_Name'] = nextProps.page_name.pay_info.Credit_Card_Holder_First_Name__c
            this.state.formData['Credit_Card_Holder_Last_Name'] = nextProps.page_name.pay_info.Credit_Card_Holder_Last_Name__c
            this.state.formData['Credit_Card_Billing_Zip_Code'] = nextProps.page_name.pay_info.Credit_Card_Billing_Zip_Code__c
            this.state.formData['CVV'] = nextProps.page_name.pay_info.CVV__c
            this.state.formData['Credit_Card_Number'] = nextProps.page_name.pay_info.Credit_Card_Number__c
           
            this.state.formData['Month_of_Expiry'] = nextProps.page_name.pay_info.Month_of_Expiry__c
            this.state.formData['Year_of_Expiry'] = nextProps.page_name.pay_info.Year_of_Expiry__c
            this.setState({Credit_Card_Number:nextProps.page_name.pay_info.Credit_Card_Number__c})
            this.state.formData['ClientSFRecordId'] = nextProps.page_name.pay_info.Id
            this.state.formData['PhysicianFirstName'] = nextProps.page_name.pay_info.First_Name__c
            this.state.formData['PhysicianLastName'] = nextProps.page_name.pay_info.Last_Name__c
           
            // this.state.formData['ClientId'] = localStorage.getItem('clientACTId');
            // this.state.formData['ClientLastName'] = localStorage.getItem('ClientLastName');

            
            this.state.card_info['Credit_Card_Type'] = nextProps.page_name.pay_info.Credit_Card_Type__c

            // this.setState({card_info:nextProps.page_name.pay_info})
            this.state.card_info['Credit_Card_Number'] = nextProps.page_name.pay_info.Credit_Card_Number__c
            this.state.card_info['CVV'] = nextProps.page_name.pay_info.CVV__c
            this.state.card_info['Credit_Card_Holder_First_Name'] = nextProps.page_name.pay_info.Credit_Card_Holder_First_Name__c
            this.state.card_info['Credit_Card_Holder_Last_Name'] = nextProps.page_name.pay_info.Credit_Card_Holder_Last_Name__c
            this.state.card_info['Month_of_Expiry'] = nextProps.page_name.pay_info.Month_of_Expiry__c
            this.state.card_info['Year_of_Expiry'] = nextProps.page_name.pay_info.Year_of_Expiry__c

            this.state.card_info['PhysicianFirstName'] = nextProps.page_name.pay_info.First_Name__c
            this.state.card_info['PhysicianLastName'] = nextProps.page_name.pay_info.Last_Name__c
            this.state.card_info['Account_Number'] = nextProps.page_name.pay_info.Account_Number__c
            this.state.card_info['Name_On_Account'] = nextProps.page_name.pay_info.Name_on_ACH_Account__c
            this.state.card_info['ABA_Routing_Number'] = nextProps.page_name.pay_info.ABA_Routing_Number__c


            if(nextProps.page_name.pay_info.Account_Number__c){
                var acc_num = nextProps.page_name.pay_info.Account_Number__c;
                this.state.acc_num = acc_num.slice(acc_num.length - 4)
                this.state.formData['Account_Number'] = nextProps.page_name.pay_info.Account_Number__c
               
            }

            if(nextProps.page_name.pay_info.ABA_Routing_Number__c){
                this.state.formData['ABA_Routing_Number'] = nextProps.page_name.pay_info.ABA_Routing_Number__c
               
            }
            
            if(nextProps.page_name.pay_info.Name_on_ACH_Account__c){
               
                this.state.formData['Name_On_Account'] = nextProps.page_name.pay_info.Name_on_ACH_Account__c
            }


            if(nextProps.page_name.pay_info.Credit_Card_Number__c){
            var nu =  nextProps.page_name.pay_info.Credit_Card_Number__c
            this.state.card_last_gid = nu.slice(nu.length - 4)
            }
            this.setState({loading:false})
            window.scrollTo(0, 0);
            
          } 

        if(nextProps.page_name.page_name && nextProps.page_name.page_name.listResponse.length > 0 ){
          
            let clientRecord = nextProps.page_name.page_name.listResponse[0];
            console.log('listResponse clientRecord-------- ',clientRecord)
            if(clientRecord.clientConsentFormCompleted == true){
                this.setState({clientConsentFormCompleted:true})
            }
            // this.state.formData['ClientId'] = clientRecord.clientRecordId
            this.state.formData['firstName'] = clientRecord.firstName
            this.state.formData['lastName'] = clientRecord.lastName
            this.state.formData['Client_lastname'] = clientRecord.lastName
            
            this.state.formData['Longevit_Cells_Availble'] = clientRecord.longevityVialsAvailable
            this.state.formData['Free_Bio_Insurance_Cells_Available'] = clientRecord.freeBioInsuranceVialsAvailble
          
            this.state.formData['clientRecordId'] = clientRecord.clientRecordId            
            this.state.formData['clientACTId'] = clientRecord.clientACTId

            localStorage.setItem('clientACTId', clientRecord.clientACTId);
            localStorage.setItem('ClientLastName', clientRecord.lastName);
           
            console.log('clientACTId ---------- ', localStorage.getItem('clientACTId'));
            console.log('ClientLastName ---------- ', localStorage.getItem('ClientLastName'));
            
            
            this.state.shippindetails['firstName'] = clientRecord.firstName
            this.state.shippindetails['lastName'] = clientRecord.lastName           
            this.state.shippindetails['phone'] = clientRecord.phone
            this.state.shippindetails['email'] = clientRecord.email
            this.state.shippindetails['preferredAddressContactFullName'] = clientRecord.preferredAddressContactFullName
            this.state.shippindetails['ShippingContactName'] = clientRecord.preferredAddressContactFullName  
            
                     

            this.state.shippindetails['ShippingEmail'] = clientRecord.shippingClinicEmail
            // this.state.formData['shippingEmail'] = clientRecord.shippingClinicEmail
            console.log('shippingEmail ---------- ', this.state.shippindetails['ShippingEmail']);
            this.state.shippindetails['city'] = clientRecord.preferredAddressCity
            this.state.shippindetails['country'] = clientRecord.preferredAddressCountry
            this.state.shippindetails['ZipCode'] = clientRecord.preferredAddressZip
            this.state.shippindetails['state'] = clientRecord.preferredAddressState
            this.state.shippindetails['street'] = clientRecord.preferredAddressStreet
          
            this.setState({loading:false})
            localStorage.setItem('client_id',clientRecord.clientRecordId);
            console.log(this.state.shippindetails);

            const currentDate = new Date();
            const BankedInDate = new Date(clientRecord.dateBankedin); // Replace with your desired exam date
            
            const timeDifference = Math.abs(BankedInDate - currentDate);
            const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

            const LastBioInsurancePurchasedDate = new Date(clientRecord.LastBioInsurancePurchasedDate); // Replace with your desired exam date
            const Bio_timeDifference = Math.abs(LastBioInsurancePurchasedDate - currentDate);
            const Bio_daysRemaining = Math.ceil(Bio_timeDifference / (1000 * 60 * 60 * 24));

            console.log('daysRemaining ---------',daysRemaining);
            
            console.log('Bio_daysRemaining ---------',Bio_daysRemaining);

            // var myFutureDate=new Date(currentDate);
            // if( daysRemaining <= 42  ){             
                
            //     myFutureDate.setDate(myFutureDate.getDate()+ (42- daysRemaining));
            //     this.setState({myFutureDate:myFutureDate})
            // }
            // else if( Bio_daysRemaining <= 21  ){             
               
            //     myFutureDate.setDate(myFutureDate.getDate()+ (21- Bio_daysRemaining));
            //     this.setState({myFutureDate:myFutureDate})
            // }else{
                
                
            // }

            console.log('last purchase date ',clientRecord.LastBioInsurancePurchasedDate);
            console.log('daysRemaining ---------',daysRemaining);
            console.log('Bio_daysRemaining ---------',Bio_daysRemaining);
            console.log('NumberOfVials ---------',this.state.formData['NumberOfVials']);
            console.log('Free_Bio_Insurance_Cells_Available ---------',this.state.formData['Free_Bio_Insurance_Cells_Available']);
            
            

            var myFutureDate=new Date(currentDate);
            if( daysRemaining < 18  && this.state.formData['Free_Bio_Insurance_Cells_Available'] == 0  ){                                 
               myFutureDate.setDate(myFutureDate.getDate()+ (42- daysRemaining));
                this.setState({myFutureDate:myFutureDate})
            }
            else if( daysRemaining > 18 && this.state.formData['Free_Bio_Insurance_Cells_Available'] == 0 ){
                myFutureDate.setDate(myFutureDate.getDate()+ (21));
                this.setState({myFutureDate:myFutureDate})
            }
            else if( daysRemaining < 42  && this.state.formData['Free_Bio_Insurance_Cells_Available'] > 0 && this.state.formData['NumberOfVials'] <= this.state.formData['Free_Bio_Insurance_Cells_Available']  ){                                 
                myFutureDate.setDate(myFutureDate.getDate()+ (42- daysRemaining));
                this.setState({myFutureDate:myFutureDate})
            }                
            else if( Bio_daysRemaining < 21 && this.state.formData['Free_Bio_Insurance_Cells_Available'] > 0 && this.state.formData['NumberOfVials'] <= this.state.formData['Free_Bio_Insurance_Cells_Available'] ){             
                
                myFutureDate.setDate(myFutureDate.getDate()+ (21- Bio_daysRemaining));
                this.setState({myFutureDate:myFutureDate})
            }
            else if( Bio_daysRemaining >= 21 && this.state.formData['Free_Bio_Insurance_Cells_Available'] > 0 && this.state.formData['NumberOfVials'] <= this.state.formData['Free_Bio_Insurance_Cells_Available']  ){             
                myFutureDate.setDate(myFutureDate.getDate()+ (2));
                this.setState({myFutureDate:myFutureDate})
            }
            else if( Bio_daysRemaining < 21 && this.state.formData['Free_Bio_Insurance_Cells_Available'] > 0 && this.state.formData['NumberOfVials'] <= this.state.formData['Free_Bio_Insurance_Cells_Available'] ){             
                myFutureDate.setDate(myFutureDate.getDate()+ (21- Bio_daysRemaining));
                this.setState({myFutureDate:myFutureDate})
            }
            else if( daysRemaining >= 42 && this.state.formData['NumberOfVials'] > this.state.formData['Free_Bio_Insurance_Cells_Available']  && this.state.formData['Free_Bio_Insurance_Cells_Available'] > 0 ){             
                
                myFutureDate.setDate(myFutureDate.getDate()+ (21));
                this.setState({myFutureDate:myFutureDate})
            }
            else if( daysRemaining < 42 && this.state.formData['NumberOfVials'] > this.state.formData['Free_Bio_Insurance_Cells_Available']  && this.state.formData['Free_Bio_Insurance_Cells_Available'] > 0 ){             
                if((42- daysRemaining) > 21){
                myFutureDate.setDate(myFutureDate.getDate()+ (42- daysRemaining));
                }else{
                myFutureDate.setDate(myFutureDate.getDate()+ (21));
                }
                this.setState({myFutureDate:myFutureDate})
            }                
            else{
                this.setState({BlokCalender:false});
              
            }

           
          }


 

          
          
          

        if(nextProps.page_name.payment_info){
            this.setState({loading:false})
            this.setState({payment_response:nextProps.page_name.payment_info.page_name})
            this.setState({transaction_id:nextProps.page_name.payment_info.TransactionId})
            this.setState({api_error:nextProps.page_name.payment_info.error})
            this.setState({loading:false})
        }

        if(nextProps.page_name.request_info){
            this.setState({loading:false})
            if(nextProps.page_name.request_info.error){
                this.setState({api_error:nextProps.page_name.request_info.error})
            }else{
                this.setState({payment_response:nextProps.page_name.request_info})
            }
            
            
        }

        if(nextProps.page_name.vial_info){
            var am = 0;
            var result = JSON.parse(nextProps.page_name.vial_info)
            this.setState({amount:result.PayableAmount})
            this.state.formData['PrePaidVials'] = result.PrePaidVials;

            this.state.formData['AmountForDoseCharged'] = result.PayableAmount;

            
            if(this.state.cpp==true){
            am = this.state.formData['NumberOfVials'] * 25
            this.setState({cpp_price:am})
            // console.log('cpp_price price t  => ', am);
            }else{
            am = 0;
            this.setState({cpp_price:0})
            }
            // console.log('cpp_price price => ', am);
            this.state.formData['CPPAmountCharged'] = am;

            console.log('cpp val => ', this.state.cpp);
            console.log('shipping value vial info -------',this.state.shipping)
            this.state.formData['amount'] = result.PayableAmount + this.state.shipping + am;
            this.setState({loading:false})

            this.checkProcessingfee();


        }
        if(nextProps.page_name.client_info){
            this.setState({loading:false})
            var re = JSON.parse(nextProps.page_name.client_info)
            // console.log('response => ',re.error)
            if(re.error != null){
                let errors = {};
                document.getElementById("Client_id").value = ""; 
                document.getElementById("Client_lastname").value = "";                               
                this.state.formData['Client_id']="";
                this.state.formData['Client_lastname']="";
                this.setState({Client_id:""}) 
                this.setState({Client_lastname:""}) 
                errors["Client_id"] = "Please enter valid Client ID or last name";
                this.setState({errors: errors});
                this.setState({success_msg:""});
            }else{

                console.log('client_info -------------',re);
                this.setState({verify_client:true})
                this.state.formData['Longevit_Cells_Availble']=re.LongevityVials;
                this.state.formData['Free_Bio_Insurance_Cells_Available']=re.BioInsuranceVials;
                this.setState({success_msg:"Client Details Successfully Confirmed"});    
           
            }
            
            
        }

        
        if(nextProps.page_name.shipping_charges){
            this.setState({loading:false})
            console.log('respone ----- ',nextProps.page_name.shipping_charges.result);
            var re = nextProps.page_name.shipping_charges.result;

            if(re.errorCode){
                let errors = {};
                console.log('shipping error ------',re);
                errors["shipping_amount"] = "Please enter valid shipping info";
                this.setState({errors: errors});

            }else{
            re = JSON.parse(nextProps.page_name.shipping_charges.result);

            console.log('shipping info ------',re);
            let amt = re[0].totalCharge;
            console.log('shipping amt ------',amt);
            var priceNum = amt.split(" ");
            console.log('priceNum ---',priceNum);      
           
            var v1 = parseFloat(priceNum[0])            
            v1 += v1 * 0.15;           

            //  this.setState({shipping:v1});
             this.state.shipping = v1;
             this.state.formData['shipping_charge']=v1;
            console.log('shipping value shipping charges api 00-------',this.state.shipping)

            console.log('shipping value v1 -------',v1)



            if(this.state.cpp == "true" || this.state.cpp == true){
                console.log('cpp ', this.state.cpp)
                let am = this.state.formData['NumberOfVials'] * 25               
                this.state.formData['amount'] = this.state.amount+ this.state.shipping + am ;
            }

            if(this.state.cpp == "false" || this.state.cpp == false){            
                console.log('cpp ', this.state.cpp)
                this.state.formData['amount'] = this.state.amount+ this.state.shipping;
            }
             
            console.log('shipping value shipping charges api -------',this.state.shipping)
            this.state.formData['ShippingFeeCharged'] = this.state.shipping;

            this.checkProcessingfee();


            
            }
            
        }



        


    }

    datechange1(e){
        // new Date(e);
        this.state.formData['ShipmentDate'] = Moment(e).format('MM/DD/YYYY');
        // console.log('selected date =>', this.state.formData['ShipmentDate']);
        const a = Moment('2024-03-28');        
        var selected_day = Moment(e).day();
        let errors = {};
        const yOffset = -80;
        let formIsValid = true;
        const date2 = Moment( new Date('12/02/2024'));
        if(this.state.formData['shipping_method']=="FedEx"){
            // console.log('selected day before =>', selected_day );
            if(selected_day == 1 || selected_day == 0){
                // console.log('selected day =>', Moment(e).day() );               
                errors["ShipmentDate"] = "FedEx does not deliver on Sunday and Monday. Please choose a different date from Tuesday to Saturday for shipment arrival";
                let element = document.getElementById("ShipmentDate");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
                
                formIsValid = false;
            }
            else if(e <= date2 ){
                // console.log('selected day =>', Moment(e).day() );
                
                errors["ShipmentDate"] = "Please contact ACT directly by phone or email for this stem cell request date.";
                let element = document.getElementById("ShipmentDate");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
                formIsValid = false;
                this.state.formData['ShipmentDate'] = "";
              
            }else{
                errors["ShipmentDate"] = "";
                let formIsValid = true;
                
            }

            if( Moment(e).isSame(a, 'day') == true){
                errors["ShipmentDate"] = "FedEx does not deliver on 28th March 2024. Please choose a different date from Tuesday to Saturday for shipment arrival";
                let element = document.getElementById("ShipmentDate");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
                formIsValid = false;
                console.log('match ------------ ');
            }
            this.setState({errors: errors});
        }

        this.setState({ShipmentDate:e})
    }

    UseShipping =(e) => {
        var checked = e.target.checked ? "True":"False";
        this.setState({shipping_value:e.target.value})
        if(e.target.value=="yes"){
            this.setState({shipping_info:checked})
            this.state.formData['ShippingInfo'] = checked;
            this.state.formData['preferredAddressContactFullName'] = this.state.shippindetails['preferredAddressContactFullName'] 
            this.state.formData['ShippingContactName'] = this.state.shippindetails['ShippingContactName'] 
            this.state.formData['ShippingEmail'] = this.state.shippindetails['ShippingEmail'] 
            
            this.state.formData['firstName'] = this.state.shippindetails['firstName'] 
            this.state.formData['lastName'] = this.state.shippindetails['lastName'] 
            this.state.formData['phone'] = this.state.shippindetails['phone'] 
            this.state.formData['street'] = this.state.shippindetails['street']
            this.state.formData['city'] = this.state.shippindetails['city']
            this.state.formData['state'] = this.state.shippindetails['state']
            this.state.formData['country'] = this.state.shippindetails['country']
            this.state.formData['ZipCode'] = this.state.shippindetails['ZipCode']
            if(this.state.shippindetails['phone'] == "" || this.state.shippindetails['phone'] == null ){
                this.state.formData['phone'] = '8444432355';
            }
            
            console.log("checked ", this.state.formData)
        }else{
            this.setState({shipping_info:checked})
            this.state.formData['ShippingInfo'] = checked;
            this.state.formData['preferredAddressContactFullName'] =""
            this.state.formData['ShippingContactName'] =""
            this.state.formData['ShippingEmail'] =""
            this.state.formData['firstName'] ="" 
            this.state.formData['lastName'] = ""
            this.state.formData['phone'] = ""
            this.state.formData['street'] = ""
            this.state.formData['city'] = ""
            this.state.formData['state'] = ""
            this.state.formData['country'] = ""
            this.state.formData['ZipCode'] = ""           
        }

        console.log(this.state.formData);
       
    }

    checkProcessingfee = (e) =>{
        if(this.state.formData['Payment_Type'] == 'CC'){
          this.state.formData['CreditCardProcessingFee'] = 0.03 * this.state.formData['amount'];
          
        }else{
          this.state.formData['CreditCardProcessingFee'] = 0;
      
        }
    }


    onSubmit = (e) => {              
        e.preventDefault();    
        // window.scrollTo(0, 500);
          
        let fields = this.state.formData;
        let errors = {};
        let formIsValid = true;
        // let scroll = {};
        const yOffset = -80;
        // console.log('verify_client ',this.state.verify_client)

        console.log('shipping val -------------',this.state.shipping_value);
        


        if(this.state.shipping_value=="no"){

        if(!fields["country"]){
            formIsValid = false;
            errors["country"] = "Please enter Country";
            let element = document.getElementById("country");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }        
       
        if(!fields["state"]){
            formIsValid = false;
            errors["state"] = "Please enter State";
            let element = document.getElementById("state");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }
        if(!fields["ZipCode"]){
            formIsValid = false;
            errors["ZipCode"] = "Please enter Zip Code";
            let element = document.getElementById("ZipCode");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["city"]){
            formIsValid = false;
            errors["city"] = "Please enter City";
            let element = document.getElementById("city");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["street"]){
            formIsValid = false;
            errors["street"] = "Please enter Street";
            let element = document.getElementById("street");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["phone"]){
            formIsValid = false;
            errors["phone"] = "Please enter phone";
            let element = document.getElementById("phone");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(fields["phone"] && isNaN(fields["phone"])){
            formIsValid = false;
            let postalcode = valid.postalCode(fields["phone"]);
            // console.log('postalcode',postalcode);
            errors["phone"] = "Please insert only number";
            let element = document.getElementById("phone");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
         } 
       
        if(!fields["ShippingEmail"]){
            formIsValid = false;
            errors["ShippingEmail"] = "Please enter Shipping Email";
            let element = document.getElementById("ShippingEmail");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(fields["ShippingEmail"] && !validator.isEmail(fields["ShippingEmail"])) {
            formIsValid = false;
            errors["ShippingEmail"] = "Invalid email.";
            let element = document.getElementById("ShippingEmail");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error" 
             
        }

        
        if(!fields["clinicName"]){
            formIsValid = false;
            errors["clinicName"] = "Please enter clinic name";
            let element = document.getElementById("clinicName");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        

        if(!fields["firstName"]){
            formIsValid = false;
            errors["firstName"] = "Please enter first name";
            let element = document.getElementById("firstName");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["lastName"]){
            formIsValid = false;
            errors["lastName"] = "Please enter last name";
            let element = document.getElementById("lastName");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

       

    } 

    // if(this.state.shipping_amount==""){
    //     formIsValid = false;
    //     errors["shipping_amount"] = "Please confirm shipping info";
    //     let element = document.getElementById("confirm_shipping");                
    //     const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    //     window.scrollTo({top: y, behavior: 'smooth'});
    //     // element.className = "form-control error"
    // }
    
    if(this.state.shipping_value=="yes"){
        console.log('inside shipping loop')
        if(this.state.formData['preferredAddressContactFullName'] == null || this.state.formData['street'] == null || this.state.formData['city'] == null || this.state.formData['state'] == null  || this.state.formData['country'] == null){
        console.log('inside shipping loop 2 ')

            formIsValid = false;
            errors["use_new_shipping_address"] = "Please select shipping address";
            let element = document.getElementById("use_new_shipping_address");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        }

        if( this.state.formData['ShippingEmail']== null){
            formIsValid = false;
            errors["ShippingEmail_label"] = "Please enter valid Shipping Email from client information page.";
            let element = document.getElementById("use_save_shipping_address");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
           // element.className = "form-control error"
        }
    }  
    
    // if(this.state.shipping_value=="yes"){

        
    // }


    if(!fields["Tissue_Info"]){
        formIsValid = false;
        errors["Tissue"] = "Please select sample type";
        let element = document.getElementById("Adipose");                
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
       
    }

    if(this.state.shipping_value==""){
        formIsValid = false;
        errors["use_new_shipping_address"] = "Please select shipping address";
        let element = document.getElementById("use_new_shipping_address");                
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
       
    }

    

    if(!fields["Infectious_Disease"]){
        formIsValid = false;
        errors["Infectious_Disease"] = "Please select Infectious Disease";
        let element = document.getElementById("treated");                
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
       
    }

    if(this.state.Infectious_Disease=="yes"){

        if(!fields["Infectious_Disease_Name"]){
            formIsValid = false;
            errors["Infectious_Disease_Name"] = "Please select sample type";
            let element = document.getElementById("Infectious_Disease_Name");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
           
        }

    }

    

    if(!fields["ShipmentDate"]){
        formIsValid = false;
        errors["ShipmentDate"] = "Please enter Shipment Date";
        let element = document.getElementById("ShipmentDate");                
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
        element.className = "form-control error"
    } 

    if(fields["ShipmentDate"]){

        var selected_day = Moment(fields["ShipmentDate"]).day();
        const date2 = Moment( new Date('12/02/2024'));

        if(this.state.formData['shipping_method']=="FedEx"){
            // console.log('selected day before =>', selected_day );
            if(selected_day == 1 || selected_day == 0){
                // console.log('selected day =>', Moment(e).day() );
                formIsValid = false;
                errors["ShipmentDate"] = "FedEx does not deliver on Sunday and Monday. Please choose a different date from Tuesday to Saturday for shipment arrival";
                let element = document.getElementById("ShipmentDate");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
                              
            }
            else if(fields["ShipmentDate"] <= date2 ){
                // console.log('selected day =>', Moment(e).day() );
                
                errors["ShipmentDate"] = "Please contact ACT directly by phone or email for this stem cell request date.";
                let element = document.getElementById("ShipmentDate");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
                formIsValid = false;
                this.state.formData['ShipmentDate'] = "";
              
            }
        }

        let dateFormat = 'MM-DD-YYYY';
        const yr = new Date(fields["ShipmentDate"]).getFullYear();
        let toDateFormat = Moment(new Date(fields["ShipmentDate"])).format(dateFormat);
        let validDate = Moment(toDateFormat, dateFormat, true).isValid();
        if(!validDate || yr >= 2050 ){
            formIsValid = false;
            errors["ShipmentDate"] = "Invalid date ";
            let element = document.getElementById("ShipmentDate");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }


    }



        if(!fields["CPP"]){
            formIsValid = false;
            errors["CPP"] = "Please Select";
            let element = document.getElementById("CPP");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["NumberOfVials"]){
            formIsValid = false;
            errors["NumberOfVials"] = "Please Select Dose";
            let element = document.getElementById("NumberOfVials");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["authorize"]){
            formIsValid = false;
            errors["authorize"] = "Please Check ";
            let element = document.getElementById("authorize");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            // element.className = "form-control error"
        }

        

        if(this.state.update_card == true){

            if(!fields["Credit_Card_Number"]){
                formIsValid = false;
                errors["Credit_Card_Number"] = "Please enter credit card number";
                let element = document.getElementById("Credit_Card_Number");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }
            if(fields["Credit_Card_Number"]) {
                let cardvalid = valid.number(fields["Credit_Card_Number"]);
                // console.log('cardvalid',cardvalid);
                if(!cardvalid.isValid){
                    formIsValid = false;
                    errors["Credit_Card_Number"] = "Invalid credit card number.";
                    let element = document.getElementById("Credit_Card_Number");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
                }
            }

            if(!fields["CVV"]){
                formIsValid = false;
                errors["CVV"] = "Please enter CVV";
                let element = document.getElementById("CVV");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }

            if(fields["CVV"] && !/^\d{3,4}$/.test(fields["CVV"])) {
                formIsValid = false;
                errors["CVV"] = "Invalid CVV code"; 
                let element = document.getElementById("CVV");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});   
                element.className = "form-control error"            
            }

            if(!fields["Credit_Card_Holder_First_Name"]){
                formIsValid = false;
                errors["Credit_Card_Holder_First_Name"] = "Please enter first name";
                let element = document.getElementById("Credit_Card_Holder_First_Name");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }

            if(!fields["Credit_Card_Holder_Last_Name"]){
                formIsValid = false;
                errors["Credit_Card_Holder_Last_Name"] = "Please enter last name";
                let element = document.getElementById("Credit_Card_Holder_Last_Name");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }

            if(!fields["Credit_Card_Billing_Email"]){
                formIsValid = false;
                errors["Credit_Card_Billing_Email"] = "Please enter billing email";
                let element = document.getElementById("Credit_Card_Billing_Email");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }

            if(fields["Credit_Card_Billing_Email"] && !validator.isEmail(fields["Credit_Card_Billing_Email"])) {
                formIsValid = false;
                errors["Credit_Card_Billing_Email"] = "Invalid email.";
                let element = document.getElementById("Credit_Card_Billing_Email");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error" 
                 
            }


            

            if(!fields["Month_of_Expiry"]){
                formIsValid = false;
                errors["Month_of_Expiry"] = "Cannot be empty";
                let element = document.getElementById("Month_of_Expiry");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
            } 
    
       

            if(!fields["Year_of_Expiry"]){
                formIsValid = false;
                errors["Year_of_Expiry"] = "Cannot be empty";
                let element = document.getElementById("Year_of_Expiry");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                } 
    
                

        }

        if(this.state.formData['Payment_Type'] == "ACH") {
          
            if(!fields["Account_Number"] && !this.state.card_info['Account_Number']){
                formIsValid = false;
                errors["Account_Number"] = "Please enter account number";
                let element = document.getElementById("Account_Number");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }
            if(!fields["Name_On_Account"] && !this.state.card_info['Name_On_Account']) {
                formIsValid = false;
                errors["Name_On_Account"] = "Field cann't be empty";
                let element = document.getElementById("Name_On_Account");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }
    
            if(!fields["ABA_Routing_Number"] && !this.state.card_info['ABA_Routing_Number']){
                formIsValid = false;
                errors["ABA_Routing_Number"] = "Field cann't be empty";
                let element = document.getElementById("ABA_Routing_Number");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }
       

        if(!fields["Account_Number"] && !this.state.card_info['Account_Number']){
            formIsValid = false;
            errors["CreditCard"] = "Please store your ACH Details in the update payment information tab to use ACH Payment Method.";
            let element = document.getElementById("ACH_payment");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        }

        

    }



        console.log('formIsValid --- ', formIsValid)
        console.log('shipping val -------------',this.state.formData);

        this.setState({errors: errors});

        // console.log('amount => ', this.state.formData['amount'])
        

        if(formIsValid){
            // this.setState({ loading: true });  
            if(this.state.formData['amount'] == 0){
                this.state.formData['amount'] = 0.05;
            }
            this.state.formData['amount'] = this.state.formData['amount']+this.state.formData['CreditCardProcessingFee'];

            const newList = this.state.formData;
            console.log("newlist=>", newList);
            this.props.setCellRequest(newList, this.props.history);
            this.setState({loading:true})
        }



    }



    render() {
        const {formData} = this.state
        if(!sessionStorage.getItem('client_id') || sessionStorage.getItem('client_id')==null) {
           
            return <Redirect to="../" />
          }
        return (
            <MainLayout>
                <Header />

                <div className="main-content">
                    <Left activeMenu={"stem-cell-request"}/> 
                    { (this.state.loading)?
                    <div class="loading-div">
                        <div class="loading-img"><img src="../images/spinner.svg" alt="" /></div>
                    </div>:""}

                    <div className="right-section">
                        <div className="pagesubheader-wrap">
                        <div className="pagesubheader-row">
                            <div className="page-subheader-col-left">
                            <div className="title-avatar">
                                <img src="images/physician-icon.svg" alt="" />
                            </div>
                            <div className="title-main">
                                <span className="title-sub-text">Client Portal</span>
                                <h1>Stem Cell Request Form</h1>
                            </div>
                            </div>
                        </div>
                        </div>
                        

                        {(this.state.clientConsentFormCompleted == false)?
                        <div class="alert alert-warning col-md-12">
                        <strong>Alert!</strong> Please complete the <a href="/form-document/Consent-form" style={{textDecoration:"underline !important"}} class="alert-link-new "> client consent </a> before ordering vials!!.
                        </div>:
                        (!formData.Credit_Card_Number && this.state.update_card == false && this.state.update_card == true  )?
                            <div class="alert alert-warning col-md-12">
                            <strong>Alert!</strong> Please update your <a href="/payment-information" class="alert-link-new " >payment information</a> before ordering vials!!.
                            </div>:
                        (this.state.payment_response)?
                        <div>
                            <h5 style={{paddingTop:"10px",margin:"10px"}}><i>Please check your email for order confirmation </i></h5>
                        </div>:  
                        <div className="inner-content-area">                      
                        <form className="needs-validation" noValidate onSubmit={this.onSubmit}>
                        
                        <div className="summary-section-wrap">                         
                        <div className="left-section-content">  
                        <span style={{color: "red"}}>{this.state.api_error}</span>
                        <div className="client-info row">                            
                            
                            <div className="form-group col-md-6">
                            <label style={{width:"100%",textAlign:"left"}} className="col-form-label">Client ID </label>
                            <div className="field-col">
                                <input type="text" id="Client_id" value={this.state.formData['clientACTId']} className="form-control"   disabled placeholder=""  />
                                <span style={{color: "red"}}></span>
                            </div>
                            </div>
                            <div className="form-group col-md-6">
                            <label style={{width:"100%",textAlign:"left"}} className="col-form-label">Client Last Name</label>
                            <div className="field-col">
                                <input type="text" className="form-control" value={this.state.formData['Client_lastname']} id="Client_lastname"  disabled placeholder=""  />
                                <span style={{color: "red"}}>{this.state.errors["Client_lastname"]}</span>
                            </div>
                            </div>
{/*                             
                            <div className="form-group col-md-6">
                            <label style={{width:"100%",textAlign:"left"}} className="col-form-label">Cell Vials Available Immediately</label>
                            <div className="field-col">
                                <input type="text" className="form-control" value={this.state.formData['Longevit_Cells_Availble']} id="Longevit_Cells_Availble" disabled onChange={this.onChange} placeholder=""  />
                            </div>
                            </div> */}

                            
                            <div className="form-group col-md-6">
                            <label style={{width:"100%",textAlign:"left"}} className="col-form-label">Pre-Paid Cell Vials Available Immediately</label>
                            <div className="field-col">
                                <input type="text" className="form-control" value={this.state.formData['Free_Bio_Insurance_Cells_Available']} id="Free_Bio_Insurance_Cells_Available" disabled onChange={this.onChange} placeholder=""  />
                            </div>
                            </div>
                            
                        </div>
                        <div className="form-wrap">
                            <div className="mid-sections">
                            <h2 className="title-2 mb-4">Stem Cell Vial Information</h2>
                            <div className="row mb-4">
                                <div className="col-md-5">
                                <div className="form-group">
                                    <label>Number of Vial(s) Requested </label>

                                    {/* 
                                    <option value="1">1 Vial $500</option>
                                    <option value="2">2 Vials $1,000</option>
                                    <option value="3">3 Vials $1,500</option>
                                    <option value="4">4 Vials $2,000</option>
                                    <option value="5">5 Vials $2,500</option>
                                    <option value="6">6 Vials $3,000</option>
                                    <option value="7">7 Vials $3,500</option>
                                    <option value="8">8 Vials $4,000</option>
                                    <option value="9">9 Vials $4,500</option>
                                    <option value="10">10 Vials $5,000</option>                     */}


                                    <select className="form-control" id="NumberOfVials" onChange={this.checkVial}>
                                    <option value="">Select One</option>
                                    <option value="1">1 Vial </option>
                                    <option value="2">2 Vials</option>
                                    <option value="3">3 Vials </option>
                                    <option value="4">4 Vials </option>
                                    <option value="5">5 Vials </option>
                                    <option value="6">6 Vials </option>
                                    <option value="7">7 Vials </option>
                                    <option value="8">8 Vials </option>
                                    <option value="9">9 Vials </option>
                                    <option value="10">10 Vials</option>        
0                                   </select>
                                    <span style={{color: "red"}}>{this.state.errors["NumberOfVials"]}</span>
                                    <span className="small-text mt-1">Each Vial is $500.00 + shipping and contains 10 million of your personal stem cells.</span>
                                </div>
                                </div>
                                <div className="col-md-5">
                                <div className="form-group">
                                    <label>Estimated vial order frequency</label>
                                    <select className="form-control" id="EstimatedVialFrequency" onChange={this.onChange}>
                                    <option>Monthly</option>
                                    <option>Quarterly</option>
                                    {/* <option>Biannauly</option> */}
                                    <option>Annually</option>
                                    <option>Frequently</option>
                                    <option>Unsure – Infrequently</option>
                                    
                                    </select>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="mid-sections">
                            <h2 className="title-2 mb-4">Shipping Method</h2>
                            <div className="row">
                                <div className="col-md-5">
                                <div className="form-group">
                                    <label>Please Choose Courier Service</label>
                                    <select className="form-control" id="shipping_method" onChange={this.onChange}>
                                    <option value="FedEx">FedEx</option>
                                    <option value="ACT VIP">ACT VIP</option>                                   
                                    </select>
                                    <span className="small-text mt-1">FedEx is $45 flat fee and ACT VIP Courier Service will be calculated based on location.You will not be charged for ACT VIP Courier Service until ACT confirms the price with you. </span>
                                </div>
                                </div>
                                {/* <div className="col-md-5">
                                <div className="form-group">
                                    <label>Delivery Speed</label>
                                    <select className="form-control" id="delivery_speed" onChange={this.onChange}>
                                    <option value="Immediate">Immediate</option>
                                    <option value="Two weeks">Two weeks</option>
                                    </select>                                   
                                </div>
                                </div> */}
                                <div className="col-md-12">
                                <h3 className="title-3 mb-0">Shipping Options</h3>
                                <div className="glove-table">
                                    <table width="100%" className="table table-bordered">
                                    <thead>
                                        <tr><th>&nbsp;</th>
                                        <th>ACT VIP Courier Service</th>
                                        <th>FedEx Priority Overnight</th>
                                        </tr></thead>
                                    <tbody><tr>
                                        <td>Price</td>
                                        <td>Varies by location</td>
                                        <td>$45.00</td>
                                        </tr>
                                        <tr>
                                        <td>Shipment Restrictions</td>
                                        <td>No Restrictions. VIP Service operates <br />24/7 365 and on all holidays</td>
                                        <td>Shipment arrivals only Tuesday-Saturdays<br /> and excluding major holidays</td>
                                        </tr>
                                        <tr>
                                        <td>Service</td>
                                        <td>Next Flight Out when your cells are ready.<br /> White Glove door to door service that will<br /> give estimated delivery time to your <br />door with updates</td>
                                        <td>FedEx Piroirty Overnight without any<br /> oversight of shipping delays or routes taken</td>
                                        </tr>
                                        <tr>
                                        <td>International Shipping</td>
                                        <td>USA and Canada</td>
                                        <td>No</td>
                                        </tr>
                                        <tr>
                                        <td>Emergency VIP Service</td>
                                        <td>VIP Courier Service works to get your cells<br /> to you as fast as possible,<br /> with complete door to door care</td>
                                        <td>No. Standard FedEx Priority Shipping</td>
                                        </tr>
                                    </tbody></table>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="mid-sections">
                            <h2 className="title-2 mb-4">Cell Protection Program (CPP)</h2>
                            <p>The <strong> Cell Protection Program (CPP)</strong> will replace your covered cells at no additional cost if cells are lost or delayed past 72 hours of preparation as a result of shipping.
                            <br /> <br /> Would you like to add the Cell Protection Program (CPP)  for $25.00 per vial?</p>
                            <div className="row">
                                <div className="col-md-5">
                                <div className="form-group">
                                    <label>Cell Protection Program (CPP)</label>
                                    <select className="form-control" id="CPP" onChange={this.onChange} value={this.state.formData['CPP']}>
                                    <option value="No">Decline CPP Coverage</option>
                                    <option value="Yes">I would like CPP on my cells</option>
                                    {/* <option value="No">No</option>                                     */}
                                    </select>
                                    <span style={{color: "red"}}>{this.state.errors["CPP"]}</span>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="mid-sections">
                            <h2 className="title-2 mb-4">Shipping Date</h2>
                            <p>If you have Bio-Insurance  Cells available, you may order your cells to be shipped on the next available business day if using FedEx Priority or shipped within 24 hours if using the ACT VIP Courier Service
                            </p>
                            </div>
                            <div className="col-md-8">
                                            <div className="form-group">
                                            <label className="col-form-label">Shipment Arrival Date (mm/dd/yyyy) <span className="required">*</span></label>
                                            <div className="field-col">
                                            <DatePicker id="ShipmentDate" className="form-control bggray" minDate={this.state.myFutureDate} placeholderText="mm/dd/yyyy" selected={this.state.ShipmentDate}  onChange={date => this.datechange1(date)} /> <br />
                                                {/* <input type="text" className="form-control" placeholder="#04/04/2021" /> */}
                                                <span style={{color: "red"}}>{this.state.errors["ShipmentDate"]}</span>
                                            </div>
                                            
                                            </div>
                                        </div>
                            <div className="mid-sections">
                            <h2 className="title-2 mb-4">Medical Professional Shipping Information</h2>
                            <div className="saved-address-wrap">
                                <h3 className="title-3">Select saved shipping address</h3>
                                <div className="saved-address custom-control custom-radio" style={{zIndex:"0"}}>
                                <input type="radio" className="custom-control-input" name="shipping_value" id="use_save_shipping_address" value="yes" onChange={this.UseShipping} />
                                {/* <input type="radio" id="customRadio2" name="customRadio" className="custom-control-input" /> */}
                                <label className="custom-control-label" htmlFor="use_save_shipping_address">
                                    <strong>{this.state.shippindetails['preferredAddressContactFullName']} </strong><br />
                                    <strong>{this.state.shippindetails['ShippingEmail']}</strong><br />
                                    <span> {this.state.shippindetails['street']}, {this.state.shippindetails['city']}, {this.state.shippindetails['state']} <br />
                                    {this.state.shippindetails['country']} {this.state.shippindetails['ZipCode']}</span><br />
                                    <span>{this.state.shippindetails['phone']}</span>
                                </label>
                                <span style={{color: "red"}}>{this.state.errors["ShippingEmail_label"]}</span>                                
                                </div>
                            </div>
                            <div className="saved-address-wrap">                            
                                <div className="saved-address custom-control custom-radio">
                                <input type="radio" className="custom-control-input" name="shipping_value" id="use_new_shipping_address" value="no" onChange={this.UseShipping} />
                                <label className="custom-control-label" htmlFor="use_new_shipping_address"> <b>Select new medical professional shipping address</b></label>
                               
                                <br />
                                <span style={{color: "red"}}>{this.state.errors["use_new_shipping_address"]}</span>
                                </div>
                            </div>
                            {(this.state.shipping_value=="no")?
                            <div className="new-address-wrap">
                                <h3 className="title-3 mb-5">New shipping address</h3>
                                <div className="form-wrap">
                                <div className="row">
                                <div className="col-md-6">
                                            <div className="form-group">
                                            <label className="col-form-label">Clinic Name <span className="required">*</span></label>
                                            <div className="field-col">
                                                <input type="text" className="form-control" id="clinicName" placeholder="" onChange={this.onChange} />
                                                <span style={{color: "red"}}>{this.state.errors["clinicName"]}</span>
                                            </div>
                                            
                                            </div>
                                        </div>
                                <div className="col-md-6">
                                            <div className="form-group">
                                            <label className="col-form-label">First Name <span className="required">*</span></label>
                                            <div className="field-col">
                                                <input type="text" className="form-control" id="firstName" placeholder="" onChange={this.onChange} />
                                                <span style={{color: "red"}}>{this.state.errors["firstName"]}</span>
                                            </div>
                                            
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                            <label className="col-form-label">Last Name <span className="required">*</span></label>
                                            <div className="field-col">
                                                <input type="text" className="form-control" id="lastName" placeholder="" onChange={this.onChange} />
                                                <span style={{color: "red"}}>{this.state.errors["lastName"]}</span>
                                            </div>
                                            
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                            <label className="col-form-label">Shipping Clinic Email <span className="required">*</span></label>
                                            <div className="field-col">
                                                <input type="text" className="form-control" id="ShippingEmail" placeholder="" onChange={this.onChange} />
                                                <span style={{color: "red"}}>{this.state.errors["ShippingEmail"]}</span>
                                            </div>
                                            
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                            <label className="col-form-label">Phone Number <span className="required">*</span></label>
                                            <div className="field-col">
                                                <input type="text" id="phone" className="form-control" onChange={this.onChange} placeholder="" disabled={this.state.form_disable} />
                                                <span style={{color: "red"}}>{this.state.errors["phone"]}</span>
                                            </div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="col-form-label">Street <span className="required">*</span></label>
                                                <div className="field-col">
                                                <input type="text" className="form-control" id="street" placeholder="" onChange={this.onChange} />
                                                <span style={{color: "red"}}>{this.state.errors["street"]}</span>
                                                </div>
                                                
                                            </div>
                                            </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                            <label className="col-form-label">City <span className="required">*</span></label>
                                            <div className="field-col">
                                                <input type="text" className="form-control" id="city" placeholder="" onChange={this.onChange} />
                                                <span style={{color: "red"}}>{this.state.errors["city"]}</span>
                                            </div>
                                            
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="col-form-label">State <span className="required">*</span></label>
                                                <div className="field-col">
                                                {/* <input type="text"  /> */}
                                                <select className="form-control" id="state" placeholder="" onChange={this.onChange}>
                                                <option value="">Select State</option>   
                                                <option value="AL">Alabama</option>
                                                <option value="AK">Alaska</option>
                                                <option value="AZ">Arizona</option>
                                                <option value="AR">Arkansas</option>
                                                <option value="CA">California</option>
                                                <option value="CO">Colorado</option>
                                                <option value="CT">Connecticut</option>
                                                <option value="DE">Delaware</option>
                                                <option value="DC">District Of Columbia</option>
                                                <option value="FL">Florida</option>
                                                <option value="GA">Georgia</option>
                                                <option value="HI">Hawaii</option>
                                                <option value="ID">Idaho</option>
                                                <option value="IL">Illinois</option>
                                                <option value="IN">Indiana</option>
                                                <option value="IA">Iowa</option>
                                                <option value="KS">Kansas</option>
                                                <option value="KY">Kentucky</option>
                                                <option value="LA">Louisiana</option>
                                                <option value="ME">Maine</option>
                                                <option value="MD">Maryland</option>
                                                <option value="MA">Massachusetts</option>
                                                <option value="MI">Michigan</option>
                                                <option value="MN">Minnesota</option>
                                                <option value="MS">Mississippi</option>
                                                <option value="MO">Missouri</option>
                                                <option value="MT">Montana</option>
                                                <option value="NE">Nebraska</option>
                                                <option value="NV">Nevada</option>
                                                <option value="NH">New Hampshire</option>
                                                <option value="NJ">New Jersey</option>
                                                <option value="NM">New Mexico</option>
                                                <option value="NY">New York</option>
                                                <option value="NC">North Carolina</option>
                                                <option value="ND">North Dakota</option>
                                                <option value="OH">Ohio</option>
                                                <option value="OK">Oklahoma</option>
                                                <option value="OR">Oregon</option>
                                                <option value="PA">Pennsylvania</option>
                                                <option value="RI">Rhode Island</option>
                                                <option value="SC">South Carolina</option>
                                                <option value="SD">South Dakota</option>
                                                <option value="TN">Tennessee</option>
                                                <option value="TX">Texas</option>
                                                <option value="UT">Utah</option>
                                                <option value="VT">Vermont</option>
                                                <option value="VA">Virginia</option>
                                                <option value="WA">Washington</option>
                                                <option value="WV">West Virginia</option>
                                                <option value="WI">Wisconsin</option>
                                                <option value="WY">Wyoming</option>
                                                </select>
                                                <span style={{color: "red"}}>{this.state.errors["state"]}</span>
                                                </div>
                                                
                                            </div>
                                            </div>
                                        

                                        <div className="col-md-6">
                                            <div className="form-group">
                                            <label className="col-form-label">Zip Code <span className="required">*</span></label>
                                            <div className="field-col">
                                                <input type="text" className="form-control" id="ZipCode" placeholder="" onChange={this.onChange} />
                                                <span style={{color: "red"}}>{this.state.errors["ZipCode"]}</span>
                                            </div>
                                            
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                            <label className="col-form-label">Country <span className="required">*</span></label>
                                            <div className="field-col">
                                            <input type="text" className="form-control" id="country" placeholder="" onChange={this.onChange} />
                                            <span style={{color: "red"}}>{this.state.errors["country"]}</span>
                                            </div>
                                            
                                            </div>
                                        </div>
                                    
                                    
                                    {/* <div className="col-md-12">
                                    <div className="form-group save-address-checkbox">
                                        <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="customCheck2" />
                                        <label className="custom-control-label" htmlFor="customCheck2">Saved Shipping Addresses</label>
                                        </div>
                                    </div>
                                    </div> */}
                                </div>
                                </div>
                            </div>:""}
                           {/* {(this.state.formData['shipping_method'] == 'FedEx')?
                            <a className="btn btn-primary" onClick={this.checkShippingAmount}>Confirm Shipping Infomation</a>
                            :""} */}
                            <span style={{color: "red"}}  id="confirm_shipping">{this.state.errors["shipping_amount"]}</span>
                            </div>
                            <div className="mid-sections">
                            <h2 className="title-2 mb-4">Tissue Information</h2>
                            <h3 class="title-3 mb-4">Please Select Sample Type :</h3>
                            <div class="choose-options-sec">
                            <div class="custom-control custom-radio">
                            <input type="radio" id="Adipose" name="Tissue" value="Adipose" class="custom-control-input" onChange={this.onChange} />
                            <label class="custom-control-label" for="Adipose">Adipose</label>
                            </div>
                            <div class="custom-control custom-radio">
                            <input type="radio" id="Bone_marrow" name="Tissue" value="Bone Marrow" class="custom-control-input" onChange={this.onChange} />
                            <label class="custom-control-label" for="Bone_marrow">Bone Marrow</label>
                            </div>        
                            <span style={{color: "red"}}>{this.state.errors["Tissue"]}</span>                                
                            </div>
                           
                            <br />
                            <h2 className="title-2 mb-4">Infectious Disease Questionaire</h2>
                            <h3 class="title-3 mb-4">Regarding the following conditions: HIV, Hepatitis B, Hepatitis C, Syphilis, Human T-lymphotropic Viruses Type I and Type II, and Cytomegalovirus.</h3>
                            <div>
                            <label class="" >Please Select one of the following responses</label>
                            <div class="custom-control custom-radio">
                            <input type="radio" id="not_treated" name="Infectious_Disease" value="not treated" class="custom-control-input" onChange={this.onChange} />
                            <label class="custom-control-label" for="not_treated">I have never been knowingly treated and cured of any of the infectious diseases listed above</label>
                            </div>
                            <div class="custom-control custom-radio">
                            <input type="radio" id="treated" name="Infectious_Disease" value="treated" class="custom-control-input" onChange={this.onChange} />
                            <label class="custom-control-label" for="treated">I HAVE been treated and cured of one of the diseases listed above.</label>
                            </div> 
                            <span style={{color: "red"}}>{this.state.errors["Infectious_Disease"]}</span>
                            <br />
                            {(this.state.Infectious_Disease=="yes")?
                            <div className="col-md-12">
                            <label>Please identify the condition and the date you were treated for and/or cured of the infectious disease</label>
                            <div className="card-dtl-value">
                            <input type="text" id="Infectious_Disease_Name" className="form-control" placeholder onChange={this.onChange} />
                            <span>{this.state.errors['Infectious_Disease_Name']} </span>
                            </div>
                            </div>:""}                                       
                            </div>

                            <br />
                            <h2 className="title-2 mb-4">Payment Information</h2>
                            <i>*Important Payment Information:
                                    A 3% credit card processing fee will be added to all transactions paid via credit card. To avoid this fee, you may choose to pay via ACH. Thank you for trusting us to safely store your personal stem cells!</i>
                                    <p></p>
                            <br />
                            <h3 class="title-3 mb-4">Please Choose a Payment Method :</h3>
                            <div class="choose-options-sec">
                            <div class="custom-control custom-radio">
                            <input type="radio" id="credit_card" name="CreditCard" class="custom-control-input" value="CC" onClick={this.checkRadio} />
                            <label class="custom-control-label" for="credit_card">Credit Card</label>
                            </div>
                           
                            <div class="custom-control custom-radio">
                            <input type="radio" id="ACH_payment" name="CreditCard" class="custom-control-input" value="ACH" onClick={this.checkRadio}/>
                            <label class="custom-control-label" for="ACH_payment">ACH Payment</label>
                            </div>                                        
                            </div>
                            <span style={{color: "red"}}>{this.state.errors["CreditCard"]}</span>
                            <br />

                            
                            <div className="payment-info" style={{width:'100%'}}>
                            {(this.state.Payment_Type == "CC")? 
                                   
                                    <div className="card">
                                        <div className="card-header">
                                        <h4>Current Payment Method Secured For Annual Banking </h4>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" id="customCheck2" defaultChecked />
                                            <label className="custom-control-label" htmlFor="customCheck2" />
                                        </div>
                                        </div>
                                        <div className="card-body">
                                        <div className="saved-card-detail">
                                            <div className="row">
                                            <div className="col-md-6">
                                                <label>Card Number</label>
                                                <div className="card-dtl-value">

                                                {(this.state.card_info['Credit_Card_Type'] == 'Mastercard') ?<img class="card-logo" src="images/master-card.png" alt="" /> :""}
                                                {(this.state.card_info['Credit_Card_Type'] == 'American Express') ?<img class="card-logo" src="images/card-3.svg" alt="" /> :""}
                                                {(this.state.card_info['Credit_Card_Type'] == 'Discover') ?<img class="card-logo" src="images/card-4.svg" alt="" /> :""}
                                                {(this.state.card_info['Credit_Card_Type'] == 'Visa') ?<img class="card-logo" src="images/card-1.svg" alt="" /> :""}
                
                                                {/* <img className="card-logo" src="images/master-card.png" alt="" /> */}


                                                <span>Credit Card ending in {this.state.card_last_gid}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label>Name on card</label>
                                                <div className="card-dtl-value">
                                                <span>{this.state.card_info['Credit_Card_Holder_First_Name']} {this.state.card_info['Credit_Card_Holder_Last_Name']}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label>Expiry</label>
                                                <div className="card-dtl-value">
                                                <span>{this.state.card_info['Month_of_Expiry']} / {this.state.card_info['Year_of_Expiry']}</span>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" id="update_card" name="update_card" className="custom-control-input" value="true" onClick={this.checkRadio3}/>
                                            <label className="custom-control-label" for="update_card">Use Another Credit Card </label>
                                          
                                        </div>

                                        {(this.state.update_card == true)?
                                        <div className="card-edit-option">
                                            <div className="card-detail-form">
                                            <div className="card-detail-wrap mb-5">
                                                <h5>Credit Card Detail <img src="images/info.svg" data-toggle="tooltip" data-placement="top" title alt="" data-original-title="Tooltip Text" /></h5>
                                                <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    <label>Credit Card Number <span className="required">*</span> </label>
                                                    <input type="text" id="Credit_Card_Number" className="form-control" placeholder="Card number" onChange={this.onChange} />
                                                    <span style={{color: "red"}}>{this.state.errors["Credit_Card_Number"]}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                    <label>CVV <span className="required">*</span> </label>
                                                    <input type="password" id="CVV" className="form-control" placeholder="***" onChange={this.onChange} />
                                                    <span style={{color: "red"}}>{this.state.errors["CVV"]}</span>
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-3">
                                                    <div className="form-group">
                                                    <label>Expiry Date <span className="required">*</span> </label>
                                                    <input type="text" className="form-control" placeholder="-- --  /  -- -- -- --" />
                                                    <span style={{color: "red"}}>{this.state.errors["CreditCard"]}</span>
                                                    </div>
                                                </div> */}

                                            <div className="col-md-6 mb-3">
                                            <label htmlFor="Month_of_Expiry">Expiration Date <span className="act_required">*</span></label>
                                            <select className="custom-select d-block w-100" id="Month_of_Expiry" onChange={this.onChange}  placeholder="mm" >
                                                <option value="">Select Month</option>
                                                <option value="01">01</option>
                                                <option value="02">02</option> 
                                                <option value="03">03</option> 
                                                <option value="04">04</option> 
                                                <option value="05">05</option>    
                                                <option value="06">06</option> 
                                                <option value="07">07</option> 
                                                <option value="08">08</option> 
                                                <option value="09">09</option> 
                                                <option value="10">10</option> 
                                                <option value="11">11</option>
                                                <option value="12">12</option>  
                                            </select>
                                            {/* <input type="text" className="form-control" id="Month_of_Expiry" onChange={this.onChange} placeholder="mm" required /> */}
                                            <span style={{color: "red"}}>{this.state.errors["Month_of_Expiry"]}</span>
                                            <div className="invalid-feedback">
                                            Valid Month required
                                            </div>
                                            </div>

                                            <div className="col-md-6 mb-3">
                                            <label htmlFor="creditCardExpirationYear"> &nbsp;</label>
                                            <select className="custom-select d-block w-100" id="Year_of_Expiry" onChange={this.onChange}  placeholder="YYYY" >
                                            <option value="">Year</option>
                                            
                                            <option value="2025">2025</option>
                                            <option value="2026">2026</option>
                                            <option value="2027">2027</option>
                                            <option value="2028">2028</option>
                                            <option value="2029">2029</option>
                                            <option value="2030">2030</option>
                                            <option value="2031">2031</option>
                                            <option value="2032">2032</option>
                                            </select>
                                            {/* <input type="text" className="form-control" id="creditCardExpirationYear" onChange={this.onChange} placeholder="yy" required /> */}
                                            
                                            <span style={{color: "red"}}>{this.state.errors["Year_of_Expiry"]}</span><div className="invalid-feedback">
                                            Valid Year required
                                            </div>
                                            </div>


                                                
                                                <div className="col-md-12">
                                                    <div className="card-logo-list">
                                                    <div><img src="../images/card-1.svg" alt="" /></div>
                                                    <div><img src="../images/card-2.svg" alt="" /></div>
                                                    <div><img src="../images/card-3.svg" alt="" /></div>
                                                    <div><img src="../images/card-4.svg" alt="" /></div>
                                                    </div>
                                                </div>
                                                </div>
                                                <div>                                            
                                                    <div className="custom-control custom-checkbox">
                                                    <br />
                                                    <input type="checkbox" id="save_card" name="save_card" className="custom-control-input" value="true" onClick={this.savecard}/>
                                                    <label className="custom-control-label" for="save_card">Save this credit card on file for future payments</label>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="card-detail-wrap">
                                                <h5>Billing Address <img src="images/info.svg" data-toggle="tooltip" data-placement="top" title alt="" data-original-title="Tooltip Text" /></h5>
                                                <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    <label>First Name</label>
                                                    <input type="text" id="Credit_Card_Holder_First_Name" className="form-control" placeholder onChange={this.onChange} />
                                                    <span style={{color: "red"}}>{this.state.errors["Credit_Card_Holder_First_Name"]}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    <label>Last Name</label>
                                                    <input type="text" id="Credit_Card_Holder_Last_Name" className="form-control" placeholder onChange={this.onChange} />
                                                    <span style={{color: "red"}}>{this.state.errors["Credit_Card_Holder_Last_Name"]}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    <label>Billing Email</label>
                                                    <input type="text" id="Credit_Card_Billing_Email" className="form-control" placeholder onChange={this.onChange} />
                                                    <span style={{color: "red"}}>{this.state.errors["Credit_Card_Billing_Email"]}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    <label>Zip/Postal Code</label>
                                                    <input type="text" id="Credit_Card_Holder_Zip"  className="form-control" placeholder onChange={this.onChange} />
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        :""}
                                        {/* <div className="act-total">
                                             Total: $ {(this.state.amount)+(this.state.shipping)+(this.state.cpp_price)}
                                        </div> */}

                                        </div>
                                    </div>:""}

                                    {(this.state.Payment_Type == "ACH")?    
                             <div className="card">
                                <div className="card-header">
                                <h4>ACH Payment Method Secured For Banking </h4>   
                                                                
                                </div>  
                                <div className="card-body">
                                {(this.state.card_info['ABA_Routing_Number'] ||  this.state.card_info['Account_Number'])?

                                    <div>
                                    <div className="saved-card-detail">                     
                                    <div class="row">          
                                        {/* <h2 className="title-2">ACH Billing Information</h2> */}
                                        <div class="col-md-4">
                                        <label> Name On Account</label>
                                        <div class="card-dtl-value">
                                        <span>{this.state.card_info['Name_On_Account']}</span>
                                        </div>
                                        </div>             
                                        <div class="col-md-4">
                                        <label>Account Number</label>
                                        <div class="card-dtl-value">
                                        <span>Account Number Ending in {this.state.acc_num}
                                        </span>
                                        </div>
                                        </div>

                                        <div class="col-md-4">
                                        <label>ABA Routing Number</label>
                                        <div class="card-dtl-value"> 
                                        <span>{this.state.card_info['ABA_Routing_Number']}
                                        </span>
                                        </div>
                                        </div>
                                    
                                    
                                    </div>
                                    </div>

                                    <br />
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" id="update_ach" name="update_ach" className="custom-control-input" value="true" onClick={this.checkAch}/>
                                        <label className="custom-control-label" for="update_ach">Use Another ACH </label>
                                        
                                    </div>


                                    <br />
                                    <div lassName="custom-control">
                                        {(!this.state.card_info['ABA_Routing_Number'] ||  !this.state.card_info['Account_Number'])?
                                        <span><b>Please store your ACH Details in the <a href="./payment-information">update payment information</a> tab.</b></span>
                                        
                                        :""}
                                     </div>
                                    </div>
                                :""}
                                 

                                 {(!this.state.card_info['ABA_Routing_Number'] ||  !this.state.card_info['Account_Number'] || this.state.update_ach)?

                                        <div className="">   
                                            <div className="card-detail-form">
                                            <div class="row">
                                            <div class="col-md-6">
                                             <div class="form-group">
                                                 <label>Name On Account <span class="required">*</span> </label>
                                                 <input type="text" id="Name_On_Account" class="form-control" placeholder="" onChange={this.onChange} />
                                                 <span style={{color: "red"}}>{this.state.errors["Name_On_Account"]}</span>
                                             </div>
                                         </div>
                                         <div class="col-md-6">
                                             <div class="form-group">
                                                 <label>Account Number<span class="required">*</span> </label>
                                                 <input type="text" id="Account_Number" class="form-control" placeholder="" onChange={this.onChange} />
                                                 <span style={{color: "red"}}>{this.state.errors["Account_Number"]}</span>
                                             </div>
                                         </div>
                                         <div class="col-md-6">
                                             <div class="form-group">
                                                 <label>ABA Routing Number <span class="required">*</span> </label>
                                                 <input type="text" id="ABA_Routing_Number" class="form-control" placeholder="" onChange={this.onChange} />
                                                 <span style={{color: "red"}}>{this.state.errors["ABA_Routing_Number"]}</span>
                                             </div>
                                         </div>
                                         <div className="col-md-12">                                            
                                                <div className="custom-control custom-checkbox">
                                                <br />
                                                <input type="checkbox" id="save_ach" name="save_ach" className="custom-control-input" value="true" onClick={this.saveAch}/>
                                                <label className="custom-control-label" for="save_ach">Save this ACH on file for future payments</label>
                                            
                                                </div>
                                        </div> 

                                        </div>
                                            </div>
                                        </div>
                                    :""}
                                </div>
                            </div>
                                :''} 

                                    </div>                                
                            </div>

                            


                            <div className="mid-sections">
                            <div className="custom-control custom-checkbox">
                             <span style={{color: "red"}}>{this.state.errors["authorize"]}</span>
                                <input type="checkbox" className="custom-control-input" id="authorize" value="yes" onChange={this.onChange} />
                                <label className="custom-control-label" htmlFor="authorize">
                                I authorize American Cell Technology to charge this card on my behalf. I understand that I will be billed any yearly storage fee related to the Client, if it has come due. This credit card information will be securely stored and used for future annual storage fees if there is not a current payment method on file. I understand that if I do not cancel my Vial(s) request 72hrs prior to the shipment date, I will be charged for the Vial(s). I understand my shipment will not be covered unless Cell Protection Program is added. American Cell Technology is not responsible once it leaves their facility.
                                <br />
                                This form is to request the Clients cells for their own personal use. These cells are from the Client's bodily tissue and therefore the Client owns them.</label>
                                <br />
                                <p>MESENCHYMAL STEM CELLS MAY ONLY BE RELEASED UPON THE REQUEST OF A LICENSED PRACTITIONER OR UPON KNOWING WAIVER OF LIABILITY MEMORIALIZED BY ACKNOWLEDGING THE FOLLOWING STATEMENT: I understand that I am requesting that ACT release and deliver to me my own personal mesenchymal stem cells without the approval of a licensed healthcare practitioner. I acknowledge that I have been advised and fully understand that my personal mesenchymal stem cells should ONLY be administered by a licensed healthcare practitioner.  I hereby release American Cell Technology and any of its employees, principals, agents, predecessors, successors and assigns from any liability arising from administration of my mesenchymal stem cells delivered at my request. </p>
                            </div>
                            </div>
                            <button type="submit" className="btn btn-primary">Submit</button><br />
                            <span style={{color: "red"}}>{this.state.api_error}</span>
                        </div>
                        </div>
                        <div class="order-summary" style={{width:'220px'}}>
                    <div class="order-summary-inner">
                        <div class="order-summary-row plan-font-size pb-4">
                        <div class="order-label">
                            <small>Order Summary</small>
                            <h2>Stem Cell Request</h2>
                        </div>

                        <div class="summary-value pt-40">
                            {/* Demo 01 */}
                        </div>
                        </div>

                        <div class="order-summary-row">
                        <div class="order-label">
                        Number of Vial(s) Requested
                        </div>
                        <div class="summary-value">
                            {this.state.formData['NumberOfVials']}
                        </div>
                        </div>
                        <div class="order-summary-row">
                        <div class="order-label">
                        Stem Cell Costs
                        </div>
                        <div class="summary-value">
                            {this.numberFormat(this.state.amount)}
                        </div>
                        </div>
                        <div class="order-summary-row">
                        <div class="order-label">
                        CPP Coverage
                        </div>
                        <div class="summary-value">
                          {this.numberFormat(this.state.cpp_price)}
                        </div>
                        </div>
                        <div class="order-summary-row">
                        <div class="order-label">
                        Processing Fee
                        </div>
                        <div class="summary-value">
                          {this.numberFormat(this.state.formData['CreditCardProcessingFee'])}
                        </div>
                        </div>

                        <div class="order-summary-row">
                        <div class="order-label">
                        Shipping
                        </div>
                        <div class="summary-value">                           
                            {this.numberFormat(this.state.shipping)}
                            {/* <NumberFormat thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'$'} value={this.state.shipping}/> */}
                        </div>
                        </div>
                    

                        

                    <div class="order-summary-row total-saving">
                      <div class="order-label">
                       <i><img src="images/saving-icon.png" alt="" /></i>  Total Price
                      </div>
                      <div class="summary-value">
                        {/* ${(this.state.amount)+(this.state.shipping)+(this.state.cpp_price)} */}
                        {this.numberFormat((this.state.amount)+(this.state.shipping)+(this.state.cpp_price) + (this.state.formData['CreditCardProcessingFee']))}
                      </div>                      
                    </div>
                    <p>* Note: If you selected ACT VIP Courier Service, ACT will reach out to you directly to confirm shipping price.</p>


                  </div>
                  
                </div>
                        </div>                        
                        </form>
                        </div>
                        } 
                        </div>

                </div>
            </MainLayout>
        );
    }
}

StemCellRequest.propTypes = {
    errors: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
    setCellRequest: PropTypes.object.isRequired,
    getClient: PropTypes.object.isRequired,
    getPaymentInfo: PropTypes.object.isRequired,
    GetClientVialsService: PropTypes.object.isRequired,
    getShippingAmount: PropTypes.object.isRequired,

};

const mapStateToProps = (state) => ({
    page_name : state.pay,       
    errors: state.errors,
  });

  export default connect(mapStateToProps, {getClient,getPaymentInfo,setCellRequest,GetClientVialsService,getShippingAmount  })(StemCellRequest);


// export default StemCellRequest;